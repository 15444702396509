import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDataContext } from "../context/dataContext";
import { useAppWidget } from "../context/AppWidgetContext";

const AppDownloadWidget = () => {
  const { profileData } = useDataContext();
  const { setShowAppWidget } = useAppWidget();
  const [appUrl, setAppUrl] = useState(null);
  const [platform, setPlatform] = useState("");

  useEffect(() => {
    if (profileData) {
      const userAgent = navigator.userAgent;
      if (/android/i.test(userAgent)) {
        setAppUrl(profileData?.android_app_link);
        setPlatform("Google Play");
      } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
        setAppUrl(profileData?.ios_app_link);
        setPlatform("App Store");
      } else {
        setAppUrl(profileData?.ios_app_link);
        setPlatform("App Store");
      }
    }
  }, [profileData]);

  return (
    <section className="widgetContainer">
      <div className="widgetOverlay"></div>
      <div className="widgetContent">
        {/* cross icon */}
        <div
          onClick={() => {
            setShowAppWidget(false);
          }}
          className="closeButton"
        >
          <RxCross2 style={{ fontSize: "20px", color: "black" }} />
        </div>
        {/* App details */}
        <div className="appDetails">
          <div className="appLogo">
            <img
              src={profileData?.business_logo_path}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="logo"
            />
          </div>
          <div>
            <h3 style={{ fontSize: "15px", margin: "0px" }} className="appName">
              {profileData?.business_name}
            </h3>
            <p style={{ margin: "0px" }} className="appPlatform">
              Free - in {platform}
            </p>
          </div>
        </div>
        <Link to={appUrl} target="_blank" className="viewButton">
          View
        </Link>
      </div>
    </section>
  );
};

export default AppDownloadWidget;
