import { createContext, useContext, useState } from "react";

export const AppWidgetContext = createContext();

export const AppWidgetProvider = ({ children }) => {
  const [showAppWidget, setShowAppWidget] = useState(true);

  return (
    <AppWidgetContext.Provider
      value={{
        showAppWidget,
        setShowAppWidget,
      }}
    >
      {children}
    </AppWidgetContext.Provider>
  );
};

export const useAppWidget = () => useContext(AppWidgetContext);
