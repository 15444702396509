import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pick from "../Assets/Images/pick-icon.png";
import Drop from "../Assets/Images/drop.png";
import Cal from "../Assets/Images/cal.png";
import Clock from "../Assets/Images/clock.png";
import hour from "../Assets/Images/hour.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { postRequest, getRequest } from "../Helpers/APIHelper";
import { useNavigate } from "react-router-dom";
import InputField from "./InputField";
import TwoColImgHover from "./TwoColImgHover";
import Modal from "react-bootstrap/Modal";
import { FaRegTimesCircle } from "react-icons/fa";
import GetInTouch from "./GetInTouch";
import ErrorModal from "./modal/ErrorModal";

const Quote = (props) => {
  const location = window.location.pathname;
  // const { setOriginAdd } = props;
  const domainName = window.location.hostname;
  const [setting, setSetting] = useState([]);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getSetting();
    }
  }, []);

  const getSetting = () => {
    getRequest("profile")
      .then((response) => {
        setSetting(response?.profile ?? []);
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
      });
  };

  const navigate = useNavigate();
  const [ptpFormData, setPtpFormData] = useState({
    from_location: null,
    from_lat: null,
    from_lng: null,
    to_location: null,
    to_lat: null,
    to_lng: null,
    via_location: null,
    via_lat: null,
    via_lng: null,
    pickup_date: moment().format("YYYY-MM-DD"),
    pickup_time: moment().format("HH:mm:ss"),
    service_type: "point_to_point",
    journey_type: "one_way",
  });
  const [start, setStart] = useState({
    from_location: null,
    from_lat: null,
    from_lng: null,
  });

  // setOriginAdd({
  //   lat: start?.from_lat,
  //   lng: start?.from_lng,
  // });

  // useEffect(() => {
  //   setOriginAdd({
  //     lat: start?.from_lat,
  //     lng: start?.from_lng,
  //   });
  //   // console.log()
  // }, [start]);

  const [end, setEnd] = useState({
    to_location: null,
    to_lat: null,
    to_lng: null,
  });

  const [via, setVia] = useState({
    via_location: null,
    via_lat: null,
    via_lng: null,
  });
  const [show, setShow] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [journeyType, setJourneyType] = useState("one_way");
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [selectedDayReturn, setSelectedDayReturn] = useState();
  const [selectedTimeReturn, setSelectedTimeReturn] = useState();
  const [pickupDate, setPickupDate] = useState(
    moment(selectedDay).format("YYYY-MM-DD")
  );
  const [pickupTime, setPickupTime] = useState(
    moment(selectedTime).format("HH:mm:ss")
  );

  const handleClose = () => setShow(false);
  const [returnDate, setReturnDate] = useState("");
  const [returnTime, setReturnTime] = useState("");
  const [btnDisable, setBtnDisable] = useState(false);
  const [viaPoint, setViaPoint] = useState(false);
  const current = new Date();

  const date =
    current.getMonth() +
    1 +
    "/" +
    current.getDate() +
    "/" +
    current.getFullYear();

  function getSelectedDay(params) {
    setSelectedDay(params);
    setPickupDate(moment(params).format("YYYY-MM-DD"));
  }

  function getSelectedTime(params, ignoreZero = true) {
    setSelectedTime(params);
    setPickupTime(moment(params).format("HH:mm:ss"));
  }
  const isSelectedDateToday = new Date().getDate() === selectedDay.getDate();

  let minTimeHour = new Date().getHours();
  let minTimeMinute = new Date().getMinutes();
  if (!isSelectedDateToday) {
    minTimeHour = 0;
    minTimeMinute = 0;
  }

  function getSelectedReturnDay(params) {
    setSelectedDayReturn(params);
    setReturnDate(moment(params).format("YYYY-MM-DD"));
  }

  function getSelectedReturnTime(params) {
    setSelectedTimeReturn(params);
    setReturnTime(moment(params).format("HH:mm:ss"));
  }

  const isSelectedDateTodayReturn =
    new Date().getDate() === selectedDayReturn?.getDate();

  let minTimeHourReturn = new Date().getHours();
  let minTimeMinuteReturn = new Date().getMinutes();
  if (!isSelectedDateTodayReturn) {
    minTimeHourReturn = 0;
    minTimeMinuteReturn = 0;
  }
  const toggleVisibility = () => {
    if (!isVisible) {
      setJourneyType("two_way");
      setPtpFormData({ ...ptpFormData, journey_type: "two_way" });
    } else {
      setJourneyType("one_way");
      setPtpFormData({ ...ptpFormData, journey_type: "one_way" });
    }
    setIsVisible(!isVisible);
    // handleOnchange();
  };

  const addViaPoint = () => {
    if (viaPoint) {
      setViaPoint(false);
      setVia();
    } else {
      setViaPoint(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var data = {
      ...ptpFormData,
      ...start,
      ...end,
      ...via,
      journey_type: journeyType,
      pickup_date: pickupDate,
      pickup_time: pickupTime,
      domain: domainName,
    };
    if (journeyType === "two_way") {
      data.return_date = returnDate;
      data.return_time = returnTime;
    } else {
      delete data["return_date"];
      delete data["return_time"];
    }
    setBtnDisable(true);
    postRequest("quote", data)
      .then((response) => {
        setBtnDisable(false);
        if (response.status) {
          if (location == "/iframe") {
            navigate("/iframe/vehicle-select");
          } else {
            navigate("vehicle-select");
          }

          localStorage.setItem("quote", JSON.stringify(response.data));
        } else {
          if (response?.data?.no_rate) {
            setShow(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error ?? "-");
        console.log("error");
      });
  };

  // let date_format = "dd/MM/yyyy";
  let date_format = "MM/dd/yyyy";
  let time_format_24 = false;

  const options = Array.from({ length: 12 }, (_, i) => i + 1);

  return (
    <>
      {btnDisable ? <div className="loading"></div> : ""}
      <div className="quote-form" id="get-quote">
        <Tabs defaultActiveKey="one_way">
          <Tab
            eventKey="one_way"
            title="One Way"
            onClick={() =>
              setPtpFormData({ ...ptpFormData, service_type: "point_to_point" })
            }
          >
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="hav-google-data">
                  <Form.Label>Where from?</Form.Label>
                  <InputGroup className="mb-3">
                    <InputField
                      go="from"
                      country={setting?.accessed_countries ?? ""}
                      placeholder="Enter Pickup Address"
                      setCoordinates={setStart}
                    />
                    <InputGroup.Text>
                      <img title="quote" src={Pick} alt="pickup" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>

                {viaPoint && (
                  <>
                    <Col lg={12} className="hav-google-data">
                      <Form.Label>Via Point</Form.Label>
                      <InputGroup className="mb-3">
                        <InputField
                          go="via"
                          country={setting?.accessed_countries ?? ""}
                          placeholder="Enter Via Address"
                          setCoordinates={setVia}
                        />
                        <InputGroup.Text>
                          <img title="quote" src={Pick} alt="via_point" />
                          <div style={{ marginLeft: "7px", cursor: "pointer" }}>
                            <FaRegTimesCircle
                              onClick={addViaPoint}
                              color="red"
                              size={25}
                            />
                          </div>
                          {/* <button
                            className="btn"
                            type="button"
                            onClick={addViaPoint}
                          >
                            x
                          </button> */}
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </>
                )}

                <Col lg={12} className="hav-google-data">
                  <div className="via-ctn">
                    <Form.Label>Where to?</Form.Label>
                    {!viaPoint && (
                      <div>
                        <button
                          className=" via-btn"
                          type="button"
                          onClick={addViaPoint}
                        >
                          {" "}
                          Add via{" "}
                        </button>
                      </div>
                    )}
                  </div>
                  <InputGroup className="mb-3">
                    <InputField
                      go="to"
                      country={setting?.accessed_countries ?? ""}
                      placeholder="Enter Destination Address"
                      setCoordinates={setEnd}
                    />
                    <InputGroup.Text className="drp-icon">
                      <img title="quote" src={Drop} alt="dropoff" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <Form.Label>When?</Form.Label>
                  <InputGroup className="mb-3">
                    {/* <Form.Control type="text" placeholder="Enter Pickup Date" /> */}
                    <DatePicker
                      selected={selectedDay}
                      required
                      dateFormat={date_format ?? "dd/MM/yyyy"}
                      onChange={(date) => getSelectedDay(date)}
                      minDate={new Date()}
                      placeholderText="Return Pickup Date"
                      className="form-control rainbow-m-vertical_x-large"
                    />
                    <InputGroup.Text>
                      <img title="quote" src={Cal} alt="phone" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <Form.Label>On?</Form.Label>
                  <InputGroup className="mb-3">
                    {/* <Form.Control type="text" placeholder="Enter Pickup Time" /> */}
                    <DatePicker
                      selected={selectedTime}
                      required
                      minTime={
                        new Date(
                          new Date().setHours(minTimeHour, minTimeMinute, 0, 0)
                        )
                      }
                      maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                      onChange={(date) => getSelectedTime(date)}
                      showTime={{ use12Hours: true, format: "HH:mm a" }}
                      showTimeSelectOnly
                      showTimeSelect
                      timeFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                      dateFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                      placeholderText="Return Pickup Time"
                      timeIntervals={15}
                      type="text"
                      className="form-control rainbow-m-vertical_x-large"
                    />
                    <InputGroup.Text>
                      <img
                        loading="lazy"
                        title="quote"
                        src={Clock}
                        alt="time"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={12} className="hav-google-data">
                  <Form.Label>Round Trip?</Form.Label>
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider" onClick={toggleVisibility}></span>
                  </label>
                </Col>
                {isVisible && (
                  <>
                    <Col lg={6}>
                      <Form.Label>Return When?</Form.Label>
                      <InputGroup className="mb-3">
                        <DatePicker
                          selected={selectedDayReturn}
                          dateFormat={date_format || "dd/MM/yyyy"}
                          disabledKeyboardNavigation
                          onFocus={(e) => e.target.blur()}
                          onChange={(date) => {
                            getSelectedReturnDay(date);
                            setPtpFormData({
                              ...ptpFormData,
                              return_date: moment(date).format("YYYY-MM-DD"),
                            });
                          }}
                          minDate={new Date()}
                          placeholderText="Pickup Date"
                          className="form-control rainbow-m-vertical_x-large"
                        />
                        <InputGroup.Text>
                          <img title="quote" src={Cal} alt="calendar" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col lg={6}>
                      <Form.Label>When On?</Form.Label>
                      <InputGroup className="mb-3">
                        <DatePicker
                          disabledKeyboardNavigation
                          onFocus={(e) => e.target.blur()}
                          selected={selectedTimeReturn}
                          minTime={
                            new Date(
                              new Date().setHours(
                                minTimeHourReturn,
                                minTimeMinuteReturn,
                                0,
                                0
                              )
                            )
                          }
                          maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                          onChange={(date) => {
                            getSelectedReturnTime(date);
                            setPtpFormData({
                              ...ptpFormData,
                              return_time: moment(date).format("HH:mm:ss"),
                            });
                          }}
                          showTime={{
                            use12Hours: true,
                            format: "HH:mm a",
                          }}
                          showTimeSelectOnly
                          showTimeSelect
                          // dateFormat="hh:mm a"
                          timeFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                          dateFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                          placeholderText="Pickup Time"
                          timeIntervals={15}
                          type="text"
                          className="form-control rainbow-m-vertical_x-large"
                        />
                        <InputGroup.Text>
                          <img title="quote" src={Clock} alt="clock" />
                        </InputGroup.Text>
                      </InputGroup>
                    </Col>
                  </>
                )}
              </Row>
              <div className="text-center">
                <Button style={{ width: "100%" }} variant="main" type="submit">
                  Get A Quote
                </Button>
              </div>
            </Form>
          </Tab>

          <Tab
            eventKey="by_hour"
            title="By the Hour"
            onClick={() =>
              setPtpFormData({ ...ptpFormData, service_type: "hourly" })
            }
          >
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={12} className="hav-google-data">
                  <Form.Label>Where from?</Form.Label>
                  <InputGroup className="mb-3">
                    <InputField
                      go="from"
                      country={setting?.accessed_countries ?? ""}
                      placeholder="Enter Pickup Address"
                      setCoordinates={setStart}
                    />
                    <InputGroup.Text>
                      <img title="quote" src={Pick} alt="pickup" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={12}>
                  <Form.Label>Duration</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Select
                      aria-label="Select Your Trip Duration"
                      required
                      onChange={(e) =>
                        setPtpFormData({
                          ...ptpFormData,
                          hourly_duration: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Your Trip Duration</option>
                      {options.map((option) => (
                        <option key={option} value={option}>
                          {option} {option === 1 ? "hour" : "hours"}
                        </option>
                      ))}
                    </Form.Select>
                    <InputGroup.Text>
                      <img title="quote" src={hour} alt="hour" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <Form.Label>When?</Form.Label>
                  <InputGroup className="mb-3">
                    <DatePicker
                      selected={selectedDay}
                      onChange={(date) => getSelectedDay(date)}
                      minDate={new Date()}
                      dateFormat={date_format ?? "dd/MM/yyyy"}
                      placeholderText="Pickup Date"
                      className="form-control rainbow-m-vertical_x-large"
                    />
                    <InputGroup.Text>
                      <img loading="lazy" title="quote" src={Cal} alt="phone" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
                <Col lg={6}>
                  <Form.Label>On?</Form.Label>
                  <InputGroup className="mb-3">
                    <DatePicker
                      selected={selectedTime}
                      minTime={
                        new Date(
                          new Date().setHours(minTimeHour, minTimeMinute, 0, 0)
                        )
                      }
                      maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                      onChange={(date) => getSelectedTime(date)}
                      showTime={{ use12Hours: true, format: "HH:mm a" }}
                      showTimeSelectOnly
                      showTimeSelect
                      timeFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                      dateFormat={`${time_format_24 ? "HH:mm" : "hh:mm a"}`}
                      placeholderText="Pickup Time"
                      timeIntervals={15}
                      type="text"
                      className="form-control rainbow-m-vertical_x-large"
                    />
                    <InputGroup.Text>
                      <img title="quote" src={Clock} alt="clock" />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              </Row>
              <div className="text-center">
                <Button variant="main" type="submit">
                  Get A Quote
                </Button>
              </div>
            </Form>
          </Tab>
        </Tabs>
      </div>
      <ErrorModal open={show} handleClose={handleClose} />
    </>
  );
};

export default Quote;
