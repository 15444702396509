import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Saloon from "../Assets/Images/saloon.png";
import Estate from "../Assets/Images/estate.png";
import Suv from "../Assets/Images/suv.png";
import Minivan from "../Assets/Images/minivan.png";

import PaxIcon from "../Assets/Images/pax-icon.png";
import LugIcon from "../Assets/Images/lug-icon.png";
import SeatIcon from "../Assets/Images/child-seat-icon.png";

const FleetBox = ({ fleets }) => {
  // console.log(fleets, "fleetsfleetsfleetsfleets");
  // const fleetlist = [
  //   {
  //     img: Saloon,
  //     title: "Sedan",
  //     pax: "4",
  //     lugg: "2",
  //     child: "1",
  //   },
  //   {
  //     img: Estate,
  //     title: "Estate",
  //     pax: "4",
  //     lugg: "2",
  //     child: "1",
  //   },
  //   {
  //     img: Suv,
  //     title: "SUV",
  //     pax: "7",
  //     lugg: "8",
  //     child: "2",
  //   },
  //   {
  //     img: Minivan,
  //     title: "Mini Van",
  //     pax: "7",
  //     lugg: "5",
  //     child: "2",
  //   },
  // ];

  if (fleets);
  return (
    <>
      <section className="fleet-wrapper section-gap">
        <Container>
          <h3 className="section-title">Our Fleet</h3>
          <Row>
            {fleets.map(
              (fleet, index) =>
                fleet.status === 1 && (
                  <Col key={index} lg={6}>
                    <div className="fleet-box">
                      <img
                        title="fleet-cpacity"
                        src={fleet.image_path}
                        alt="fleets"
                      />
                      <h4>{fleet.name}</h4>
                      <div className="capacity-wrap">
                        <span>
                          <img
                            title="fleet-cpacity"
                            src={PaxIcon}
                            alt="passenger_icon"
                          />{" "}
                          {fleet.max_passenger}
                        </span>
                        <span>
                          <img
                            title="fleet-cpacity"
                            src={LugIcon}
                            alt="luggage_icon"
                          />{" "}
                          {fleet.max_luggage}
                        </span>
                        <span>
                          <img
                            title="fleet-cpacity"
                            src={SeatIcon}
                            alt="seat_icon"
                          />{" "}
                          {fleet.max_baby_seat}
                        </span>
                      </div>
                    </div>
                  </Col>
                )
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default FleetBox;
