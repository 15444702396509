import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import CallIcon from "../Assets/Images/call-icon-bg.png";
import WhatsappIcon from "../Assets/Images/whatsapp-icon.png";
import { getRequest } from "../Helpers/APIHelper";
import appStoreLogo from "../Assets/Images/g-play.png";
import googlePlayLogo from "../Assets/Images/app-store.webp";
import { useDataContext } from "../context/dataContext";

const Footer = () => {
  const { profileData } = useDataContext();

  return (
    <>
      <div className="footer_wrapper">
        <Container>
          <Row>
            <Col lg={6}>
              <p>
                Copyright @{new Date().getFullYear()}.{" "}
                <span>{profileData?.business_name ?? ""}</span>
              </p>
            </Col>
            <Col lg={6} className="ft-links">
              <Link to="/terms-of-services">Terms & Conditions</Link>
              <Link to="/privacy-policy"> Privacy Policy</Link>
            </Col>

            {profileData?.android_app_link && (
              <Col lg={6}>
                <div className="download-section">
                  <Link target="_blank" to={profileData?.ios_app_link}>
                    <img
                      title="app_link"
                      loading="lazy"
                      style={{
                        height: "55px",
                        width: "170px",
                        objectFit: "contain",
                      }}
                      width={100}
                      height={200}
                      src={appStoreLogo}
                      alt="app-store"
                    />
                  </Link>
                  <Link target="_blank" to={profileData?.android_app_link}>
                    <img
                      title="app_link"
                      loading="lazy"
                      style={{
                        objectFit: "contain",
                        width: "170px",
                        height: "55px",
                      }}
                      width={100}
                      height={200}
                      src={googlePlayLogo}
                      alt="google-play"
                    />
                  </Link>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>
      {/* <Container className="navbar-contacts">

        <div className="call-link">
          <Link to={'tel:'+profile?.mobile??''} target="_blank">
            <img src={CallIcon} alt="Logo" />
          </Link>
        </div>
        <div className="whatsapp-logo">
          <Link to={'https://api.whatsapp.com/send?phone='+profile?.whatsapp_number??''} target="_blank">
            <img src={WhatsappIcon} alt="Logo" />
          </Link>
        </div>
        <div>
          <Button variant="main"><a href="#get-quote"> Get A Quote</a></Button>
        </div>

      </Container> */}
    </>
  );
};

export default Footer;
