import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import axios from "axios";
import useLocalStorage from "../../hooks/useLocalStorage";
import { getRequest } from "../../Helpers/APIHelper";
import ForSeo from "../../Components/seo/ForSeo";
import PaxIcon from "../../Assets/Images/pax-icon.png";
import LugIcon from "../../Assets/Images/lug-icon.png";
import SeatIcon from "../../Assets/Images/child-seat-icon.png";
import yes from "../../Assets/Images/yes.png";
import { config } from "../../Helpers/Constants";
import { showToast } from "../../Components/ShowToast";
import { toast } from "react-toastify";
const apiUrl = config.API_URL;

const NewThankYou = (props) => {
  const navigate = useNavigate();
  const { getItem } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const booking_ref = JSON.parse(searchParams.get("booking_ref"));
  const return_booking_ref = JSON.parse(searchParams.get("return_booking_ref"));
  const paymentStatus = searchParams.get("payment");
  const oneWay_bookingData = getItem("one_way");
  const twoWay_bookingData = getItem("two_way");
  const thankMsg = getItem("thankMsg");
  const [isInvoiceDownloading, setIsInvoiceDownloading] = useState(false);

  // -------for-seo-----
  const [seo, setSeo] = useState({});
  const location = useLocation();
  const canonicalURL = window?.location?.host;
  const siteURL = window?.location?.href;

  const currentURL = window.location.pathname;

  useEffect(() => {
    getSeo();
  }, []);

  useEffect(() => {
    if (!getItem("fare_data")) {
      navigate("/");
    }
  }, [getItem("fare_data")]);

  const getSeo = async () => {
    const res = await getRequest(
      `header-seo-data?slug=${location.pathname.substring(1)}`
    );
    setSeo(res);
  };

  useEffect(() => {
    setMessage(localStorage.getItem("thankYouMsg"));
    localStorage.removeItem("thankYouMsg");
  }, []);

  const handleVoucherDownload = async (e) => {
    e.preventDefault();
    setIsInvoiceDownloading(true);
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${apiUrl}quote/download-statement?booking_id=${booking_ref}`,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsInvoiceDownloading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url, "_blank");
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the voucher:", error);
      setIsInvoiceDownloading(false);
    }
  };

  const getTotalFare = (a, b) => {
    var c = b ? a + b : a;
    return `${oneWay_bookingData?.currency}${c}`;
  };

  return (
    <>
      <ForSeo
        canonicalUrl={canonicalURL}
        title={seo?.title || "Thank You For Booking"}
        description={seo?.description || props?.desc}
        keywords={seo?.keywords || ""}
        siteUrl={canonicalURL || ""}
      />

      <div className="thank-you-ctn">
        <div className="thank-you-card">
          <h4 className="title">
            {" "}
            {/* {paymentStatus
              ? "Payment has Declined !"
              : "Payment made Successfully"} */}
            {thankMsg}
          </h4>

          <div
            style={{
              textAlign: oneWay_bookingData?.to_location ? "start" : "center",
            }}
            className="address-card-ctn"
          >
            <span>
              {oneWay_bookingData?.service_type === "hourly"
                ? "Hourly"
                : "One Way"}
            </span>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: oneWay_bookingData?.to_location
                  ? "space-between"
                  : "center",
                alignItems: "center",
              }}
              className="address-ctn"
            >
              <div className="address">
                <label>Pickup Address</label>
                <p>{oneWay_bookingData?.from_location}</p>
              </div>

              {oneWay_bookingData?.via && (
                <>
                  <div className="way-line"></div>
                  <div className="address">
                    <label>Via</label>
                    {oneWay_bookingData?.via?.map((item, ind) => (
                      <p>{item?.location}</p>
                    ))}
                  </div>
                </>
              )}

              {oneWay_bookingData?.to_location && (
                <>
                  <div className="way-line"></div>
                  <div className="address">
                    <label>Dropoff Address</label>
                    <p>{oneWay_bookingData?.to_location}</p>
                  </div>
                </>
              )}
            </div>

            <div className="duration-ctn">
              {oneWay_bookingData?.distance !== 0 && (
                <p>
                  {" "}
                  Distance :{" "}
                  <strong>{oneWay_bookingData?.distance_text}</strong>{" "}
                </p>
              )}
              {oneWay_bookingData?.duration !== 0 && (
                <p>
                  {" "}
                  Duration :{" "}
                  <strong>{oneWay_bookingData?.duration_text}</strong>
                </p>
              )}
              {oneWay_bookingData?.hourly_duration !== 0 && (
                <p>
                  {" "}
                  Duration :{" "}
                  <strong>{oneWay_bookingData?.hourly_duration} hr</strong>
                </p>
              )}
            </div>
          </div>

          {twoWay_bookingData && (
            <div
              style={{
                textAlign: oneWay_bookingData?.to_location ? "start" : "center",
              }}
              className="address-card-ctn"
            >
              <span>{twoWay_bookingData && "Two Way"}</span>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: twoWay_bookingData?.to_location
                    ? "space-between"
                    : "center",
                  alignItems: "center",
                }}
                className="address-ctn"
              >
                <div className="address">
                  <label>Pickup Address</label>
                  <p>{twoWay_bookingData?.from_location}</p>
                </div>

                {twoWay_bookingData?.via && (
                  <>
                    <div className="way-line"></div>
                    <div className="address">
                      <label>Via</label>
                      {twoWay_bookingData?.via?.map((item, ind) => (
                        <p>{item?.location}</p>
                      ))}
                    </div>
                  </>
                )}

                {twoWay_bookingData?.to_location && (
                  <>
                    <div className="way-line"></div>
                    <div className="address">
                      <label>Dropoff Address</label>
                      <p>{twoWay_bookingData?.to_location}</p>
                    </div>
                  </>
                )}
              </div>

              <div className="duration-ctn">
                {oneWay_bookingData?.distance !== 0 && (
                  <p>
                    {" "}
                    Distance :{" "}
                    <strong>{oneWay_bookingData?.distance_text}</strong>{" "}
                  </p>
                )}
                {oneWay_bookingData?.duration !== 0 && (
                  <p>
                    {" "}
                    Duration :{" "}
                    <strong>{oneWay_bookingData?.duration_text}</strong>
                  </p>
                )}
              </div>
            </div>
          )}

          {/* {oneWay_bookingData?.lead_passenger_name */}

          <div className="personal-info-container">
            <div className="outer">
              <div className="personal-info-ctn">
                <label style={{ padding: "5px 0px" }}>Passenger Info </label>
                <div>
                  <p className="sub-title">
                    {" "}
                    Name : {oneWay_bookingData?.name}
                  </p>
                  <p className="sub-title">
                    {" "}
                    Phone :{oneWay_bookingData?.mobile}
                  </p>
                  <p className="sub-title">
                    {" "}
                    Email : {oneWay_bookingData?.email}
                  </p>
                </div>
              </div>

              {oneWay_bookingData?.lead_passenger_name && (
                <div className="personal-info-ctn">
                  <label style={{ padding: "5px 0px" }}>
                    Lead Passenger Info{" "}
                  </label>
                  <div>
                    <p className="sub-title">
                      {" "}
                      Name : {oneWay_bookingData?.lead_passenger_name}
                    </p>
                    <p className="sub-title">
                      {" "}
                      Phone : {oneWay_bookingData?.lead_passenger_phone}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="fleet-info-ctn">
            <div className="fleet-info">
              {/* <label style={{ padding: "5px 0px" }}>Fleet info </label> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                <div>
                  <label style={{ padding: "5px 0px" }}>Fleet info </label>
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        title="capacity"
                        className="fleet-image"
                        src={oneWay_bookingData?.fleet_image}
                        alt="fleet"
                      />
                    </div>
                    <div className="">
                      <div>
                        <h5> {oneWay_bookingData?.fleet_name}</h5>
                        <div className="capacity-wrap">
                          <span style={{ marginRight: "10px" }}>
                            <img
                              title="capacity"
                              src={PaxIcon}
                              alt="passenger_icon"
                            />{" "}
                            {oneWay_bookingData?.passenger_count ?? 1}
                          </span>
                          <span style={{ marginRight: "10px" }}>
                            <img
                              title="capacity"
                              src={LugIcon}
                              alt="luggage_icon"
                            />{" "}
                            {oneWay_bookingData?.luggage_count ?? 0}
                          </span>
                          <span style={{ marginRight: "10px" }}>
                            <img
                              title="capacity"
                              src={SeatIcon}
                              alt="seat_icon"
                            />{" "}
                            {oneWay_bookingData?.baby_seat_count ?? 0}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {oneWay_bookingData?.flight_name && (
                  <div className="personal-info-ctn">
                    <label style={{ padding: "5px 0px" }}>
                      Flight Information{" "}
                    </label>
                    <div>
                      <p className="sub-title">
                        {" "}
                        Flight Number : {oneWay_bookingData?.flight_name}{" "}
                      </p>
                      <p className="sub-title">
                        {" "}
                        Phone : {oneWay_bookingData?.flight_number}{" "}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <div className="fleet-info-ctn">
            <div className="fleet-info">
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "500px",
                  margin: "0 auto",
                }}
              >
                <div>
                  <label style={{ padding: "5px 0px" }}> Driver Note </label>
                  <div>{oneWay_bookingData?.special_instruction}</div>
                </div>
              </div>
            </div>
          </div> */}

          {oneWay_bookingData?.special_instruction && (
            <div className="personal-info-container">
              <div className="outer">
                <div className="personal-info-ctn">
                  <label style={{ padding: "5px 0px" }}>Driver Note</label>
                  <div>{oneWay_bookingData?.special_instruction}</div>
                </div>
              </div>
            </div>
          )}

          <div className="fare-container">
            <div className="one">
              <p>OneWay Fare</p>
              <p>
                {oneWay_bookingData?.currency}
                {oneWay_bookingData?.total_fare}
              </p>
            </div>
            {twoWay_bookingData && (
              <div className="one">
                <p>TwoWay Fare</p>
                <p>
                  {" "}
                  {twoWay_bookingData?.currency}
                  {twoWay_bookingData?.total_fare}
                </p>
              </div>
            )}
            <div className="one">
              <p style={{ fontSize: "26px", fontWeight: "500" }}>Total</p>
              <p style={{ fontSize: "36px", fontWeight: "600" }}>
                {getTotalFare(
                  oneWay_bookingData?.total_fare,
                  twoWay_bookingData?.total_fare
                )}{" "}
              </p>
            </div>
          </div>

          <div className="yes-ctn">
            <img src={yes} alt="" />
          </div>
        </div>

        <div className="button-group">
          <button
            onClick={handleVoucherDownload}
            disabled={isInvoiceDownloading}
            style={{
              width: "300px",
              display: "flex",
              gap: "5px",
              borderRadius: "25px",
              justifyContent: "center",
              alignItems: "center",
              background: "#ededed",
              outline: "none",
              border: "none",
              opacity: isInvoiceDownloading ? 0.6 : 1,
            }}
            className="custom-btn-1"
            variant="main"
            type="submit"
          >
            <FaFileDownload />
            {isInvoiceDownloading ? "Downloading..." : "Download Voucher"}
            {/* Download Voucher */}
          </button>
          <button
            onClick={() => navigate("/")}
            style={{ width: "max-content", borderRadius: "25px" }}
            className="custom-btn"
          >
            Go To Home
          </button>
        </div>
      </div>
    </>
  );
};

export default NewThankYou;
